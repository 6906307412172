<template>
    <div class="bg-grind text-white shadow-lg">
        <div class="container flex items-center py-4 md:py-6 lg:py-8">
            <div class="flex flex-1 items-center">
                <button @click="onLeaveToHome">
                    <img
                        src="~/assets/images/roastar_logo_white.svg"
                        alt="Roastar Logo"
                        class="hidden h-10 lg:inline"
                    />
                    <img
                        src="~/assets/images/roastar-star-logo-white.svg"
                        alt="Roastar Logo"
                        class="w-12 lg:hidden"
                    />
                </button>
            </div>

            <div class="flex w-1/2 items-center justify-center">
                <h1
                    class="text-center text-[32px] sm:text-[42px] md:text-4xl lg:text-6xl"
                >
                    Product Finder
                </h1>
            </div>

            <div class="flex flex-1 items-center justify-end">
                <button
                    data-cy="product-finder-leave"
                    @click="onLeaveToReturnUrl"
                >
                    <Icon
                        name="xmark"
                        class="text-[40px] md:text-[50px] lg:text-[55px]"
                    />
                </button>
            </div>
        </div>
    </div>

    <ProductFinderLeaveConfirmationModal ref="leaveConfirmationModal" />
</template>

<script setup lang="ts">
import ProductFinderLeaveConfirmationModal from '../ProductFinderLeaveConfirmationModal.vue';

const leaveConfirmationModal = ref<InstanceType<
    typeof ProductFinderLeaveConfirmationModal
> | null>(null);

const props = defineProps<{
    hash: string;
    returnUrl: string;
}>();

const isResultsView = computed(() => props.hash.includes('#results'));

const onLeaveToHome = () => {
    if (isResultsView.value) {
        navigateTo('/', { external: true });
    } else {
        leaveConfirmationModal.value?.open('/');
    }
};

const onLeaveToReturnUrl = () => {
    if (isResultsView.value) {
        navigateTo(props.returnUrl, { external: true });
    } else {
        leaveConfirmationModal.value?.open(props.returnUrl);
    }
};
</script>
