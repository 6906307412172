<template>
    <div class="container">
        <div
            class="flex items-center gap-12 pb-10 pt-8 md:py-20 lg:gap-32 xl:gap-40 xl:py-28"
        >
            <div
                class="hidden w-2/5 flex-shrink-0 md:block lg:w-1/3 xl:w-[430px]"
            >
                <img
                    src="~/assets/images/product-finder/sterling-here-to-help.svg"
                    alt="Meet Sterling - He's here to help!"
                    class="w-full"
                    width="430"
                    height="539"
                />
            </div>

            <div>
                <h1 class="text-[36px] xs:text-[42px] md:text-4xl lg:text-6xl">
                    Find your perfect coffee packaging.
                </h1>

                <p class="mt-10 flex items-start gap-6 text-base">
                    <img
                        src="~/assets/images/product-finder/sterling.svg"
                        alt="Sterling"
                        class="w-24 flex-shrink-0 md:hidden"
                    />

                    <span>
                        All you have to do is answer 3 simple questions, then
                        we’ll have product recommendations and links to
                        resources about the packaging process.
                    </span>
                </p>

                <p class="mt-10 text-base md:mt-6">
                    <em>
                        Ready to find your match? This quiz will only take you
                        ~30 seconds.
                    </em>
                </p>

                <a
                    href="#steps"
                    class="btn-primary mt-10 w-full px-16 sm:w-auto"
                >
                    Get Started
                </a>
            </div>
        </div>
    </div>
</template>
